import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import SEO from "./SEO";
import PageCover from "../components/PageCover";

class Layout extends Component {
  render() {
    return (
      <div className="layout-cover">
        <SEO
          title={this.props.title}
          description={this.props.description}
          image={this.props.image}
        />
        <PageCover
          backgroundImage={this.props.imageDesktop}
          backgroundImageMobile={this.props.imageMobile}
          backgroundColor="#040C15"
        >
          <Header />
          <div>
            <main>{this.props.children}</main>
          </div>
          <Footer pageSource="home" />
        </PageCover>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
