import React, { Component } from "react";
import { Link } from "gatsby";
import Twitter from "../../icons/twitter.svg";
import Github from "../../icons/github.svg";
import LinkedIn from "../../icons/linkedin.svg";

class Footer extends Component {
  render() {
    if (this.props.pageSource === "home") {
      return (
        <div className="footer bg-opacity-0">
          <div className="fixed bottom-6 right-6">
            <a
              href="https://twitter.com/bilalelreda"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter height="25" width="25" fill="#FFF" />
            </a>
            <br />
            <a
              href="https://github.com/bilalelreda"
              target="_blank"
              rel="noreferrer"
            >
              <Github height="25" width="25" fill="#FFF" />
            </a>
            <br />
            <a
              href="https://www.linkedin.com/in/bilalelreda/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn height="25" width="25" fill="#FFF" />
            </a>
          </div>
          <div className="flex fixed bottom-6 left-6 place-content-left">
            <Link to="/1998" className="text-white">
              /1998
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="footer bg-opacity-0">
          <div className="fixed bottom-6 right-6">
            <a
              href="https://twitter.com/bilalelreda"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter height="25" width="25" fill="#FFF" />
            </a>
            <br />
            <a
              href="https://github.com/bilalelreda"
              target="_blank"
              rel="noreferrer"
            >
              <Github height="25" width="25" fill="#FFF" />
            </a>
            <br />
            <a
              href="https://www.linkedin.com/in/bilalelreda/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn height="25" width="25" fill="#FFF" />
            </a>
          </div>
        </div>
      );
    }
  }
}
export default Footer;
