import React, { Component } from "react";
import BackgroundImage from "gatsby-background-image";

class PageCover extends Component {
  render() {
    const source = [
      this.props.backgroundImageMobile,
      {
        ...this.props.backgroundImage,
        media: "(min-width: 1024px)",
      },
    ];

    return (
      <BackgroundImage
        Tag="section"
        fluid={source}
        preserveStackingContext={true}
        backgroundColor={this.props.backgroundColor}
        loading="eager"
        className="page-cover"
      >
        <div>{this.props.children}</div>
      </BackgroundImage>
    );
  }
}

export default PageCover;
